import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zAppDevFormModalComponent } from './form-modal.component';
import { zAppDevModalModule } from '@framework/components/Modal/Modal.module';
import { RouterModule } from '@angular/router';
import { zAppDevButtonModule } from '../Button/Button.module';
import { zAppDevIconismModule } from '../Iconism/Iconism.module';
import { zAppDevLabelModule } from '../Label/Label.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    zAppDevModalModule,
    zAppDevButtonModule,
    zAppDevIconismModule,
    zAppDevLabelModule
  ],
  declarations: [zAppDevFormModalComponent],
  providers: [],
  exports: [zAppDevFormModalComponent],
})
export class zAppDevFormModalModule {
  static forRoot(): ModuleWithProviders<zAppDevFormModalModule> {
    return {
      ngModule: zAppDevFormModalModule,
      providers: [],
    };
  }
}
