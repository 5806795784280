
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ClientCommandsService } from '@services/ClientCommands.service';
import { ContextService } from './@core/services/Context.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  options = {
    className: 'p-confirm-popup-info'
  };
  title = 'ServicesHub';

  constructor(
    protected readonly client: ClientCommandsService,
    protected readonly router: Router,
    protected readonly translate: TranslateService,
    protected readonly contextService: ContextService,
    protected readonly titleService: Title) {
    this.titleService.setTitle(`Application loading... | ${this.title}`);
  }
  
  ngOnInit() {
    this.client.onConfirmOptions()
      .subscribe((options => {
        this.options = options;
      }));

        this.contextService.context$.subscribe((context) => {
      this.translate.use(context.currentLanguage.languageIetTag);
    });

  }
}