import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map, tap, filter, catchError } from "rxjs/operators";
import { Location } from "@angular/common";
import {
  ClientCommands,
  ClientCommandsService,
} from "../../@core/services/ClientCommands.service";
import { ServerErrorHandlerService } from "src/app/@core/services/ServerErrorHandler.service";
import { Joove } from "../core/Joove";
import { FriendlyMessageDTO } from "../../@core/services/ServerErrorHandler.model";

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private location: Location,
    private clientCommandsService: ClientCommandsService,
    private serverErrorHandlerService: ServerErrorHandlerService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: any) => {
        //  event = JSON.parse(event.body.Data);
        // console.log('---------------- event', event);
        //  return event;

        if (event == null || event.body == null) {
          //todo: handle
          return event;
        }

        if (event.body == null || event.body.Type == null) {
          //TODO: handle
          return event;
        }

        let data = event.body;

        if (data.ClientCommands != null) {
          this.clientCommandsService.execute(data.ClientCommands);
        }

        // console.log('data ---------', data);

        switch (data.Type) {
          case "Redirect":
            if (data.Url != null && data.Url.trim() !== "") {
              //if (modalOptions != null) {
              //  console.error("Redirecting to URL inside modal after ajax call not implemented!"); //TODO: handle
              //}
              if (data.Url === "CLOSE_FORM") {
                this.clientCommandsService.executeCommand(
                  ClientCommands.CLOSE_FORM
                );
                return;
              } else {
                //window.location.href = data.Url; // TODO: handle
              }
            }

            data.QueryParameters = data.QueryParameters ?? [];
            var queryParams = data.QueryParameters.length == 0 ? "" : "/" + (data.QueryParameters ?? []).join("/");
            this.router.navigate([`${data.Controller}/${data.Action}${queryParams}`]);
            return;
          case "Data":
          case "DatasourceData":
          case "UpdateInstance":
            //Core.updateViewModel(controller, data.Data, rootModelInfo);
            //window._ruleEngine.update(Joove.EvaluationTimes.OnChange, null, afterRulesAppliedCb);
            //if (cb && typeof cb === "function")
            //  cb(data.Data);
            data = JSON.parse(data.Data);

            if (data?.ClientCommands != null) {
              this.clientCommandsService.execute(data.ClientCommands);
            }

            //console.log(data);
            //console.log(event);
            return event.clone({ body: data });
          case "ActionReturnValue":
          case "Error":
            throw new Error(`'${data.Type}' not implemented`);
          case "RuleEvaluation":
          case "Unauthorized":
            alert("Unauthorized: TODO show appropriate message.");
            break;
          case "FileUpload":
          case "FileDownload":
            return event.clone({body: data});
          default:
            throw new Error(`'${data.Type}' not implemented`);
        }

        return event;
      }),
      filter((event: any) => {
        if (event == null || event.body == null || event.body.Type == null) {
          return true;
        }

        switch (event.body.Type) {
          case "Redirect":
            return false;
        }

        return true;
      }),
      catchError((event: any) => {
        let friendlyMessage = Joove.Common.stringIsNullOrEmpty(event.error.Data) ? new FriendlyMessageDTO() : JSON.parse(event.error.Data);
        let dialogParams =  this.serverErrorHandlerService.enrichExceptionMessage(friendlyMessage);
        this.clientCommandsService.handleShowMessage(dialogParams);
        return event;
      })
    );
  }
}
