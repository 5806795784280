import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Joove } from "../../@framework/core/Joove";
import { first } from "rxjs/operators";
import { ContextService } from '../services/Context.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private contextService: ContextService) { }

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req);

    if (req.method !== "GET" || req.url.indexOf('_Context_') < 0) {
      return next.handle(req);
    }

    let context: any;
    this.contextService.context$.pipe(first())
      .subscribe((data: Joove.IContext) => context = data);

    if (context?.currentUser != null) { // TODO: find a more relaible way to check if the context has been initialized
      return of(context)
    }

    return next.handle(req);
  }
}
