import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PubSubService } from '../../../@core/services/PubSub.service';
import { ZAppDevModalComponent } from '../Modal/modal.component';

@Component({
  selector: 'zapp-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.less']
})
export class zAppDevFormModalComponent implements OnInit, AfterViewInit {

  constructor(
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected pubSubService: PubSubService
  ) {

    const currentNavigation = this.router.getCurrentNavigation();
    if (currentNavigation?.extras?.state != null) {
      this.style = currentNavigation.extras.state.style ?? this.style;
      this.data = currentNavigation.extras.state.data;
    }

  }
  
  @ViewChild('FormModal')
  formModal: ZAppDevModalComponent;

  style = 'width: 100vw';
  data: any;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.formModal.showDialog();
  }

  closeModal() {

    if (this.data?.executeOnClose != null) {
      this.pubSubService.publish('form-modal-close', 'CLOSE_FORM', { data: this.data });
    }

    const navigationExtras: NavigationExtras = {
      state: {
        data: this.data
      }
    };

    this.router.navigate([{ outlets: { 'modal': null } }], navigationExtras);
  }

}
