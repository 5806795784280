<p-dialog [(visible)]="display"
          [closable]="false"
          [styleClass]="dialogClass"
          [style]="_style"
          position="center"
          [closeOnEscape]="true"
          [focusOnShow]="true"
          [dismissableMask]="true"
          [modal]="true" >
    <p-header>
      <ng-container *ngTemplateOutlet="headerTpl">
      </ng-container>
    </p-header>
    <ng-content></ng-content>
    <p-footer>
      <ng-container *ngTemplateOutlet="footerTpl"></ng-container>
    </p-footer>
</p-dialog>
