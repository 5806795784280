import { Directive, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../@core/services/Auth.service';
import { CF_COMPONENT, IConditionalFormattingComponent } from '../../rule-engine/directives/condition-formatting.directive';
import { PermissionService } from '../services/permission.service';

@Directive({
  selector: '[zappPermissionsOnly]'
})
export class PermissionsOnlyDirective {

  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _permissionsService: PermissionService) { }

  @Input() set zappPermissionsOnly(permissions: string[]) {
    if (this._permissionsService.hasPermission(permissions)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}

@Directive({
  selector: '[zapphaspermission]'
})
export class HasPermissionDirective implements OnDestroy {
  private hasView = false;
  private permissionsSub: Subscription;
  private _component: IConditionalFormattingComponent;

  constructor(
    private elementRef: ElementRef<any>,
    private _permissionsService: PermissionService,
    private authService: AuthService,
    @Optional() @Inject(CF_COMPONENT) component: IConditionalFormattingComponent | null) {
    if (component?._elementRef != null && component?._elementRef?.nativeElement === elementRef.nativeElement) {
      this._component = component;
    }
  }

  @Input() set zapphaspermission(data: ActionAuthorization) {

    if (data == null) {
      return;
    }

    this.permissionsSub = this._permissionsService.permissions.subscribe((permissions) => {

      if ((data.allowAnonymous && !this.authService.isLoggedIn())
        || (data.allowAuthenticated && this.authService.isLoggedIn())
        || data.permissions?.some(p => permissions?.some(per => per === p))) {

        if (this._component?.noPermission == true) {
          this._component.noPermission = false;
          $(this.elementRef.nativeElement).show();
          this.elementRef.nativeElement.disabled = undefined;
        }
        return;
      }

      this._component.noPermission = true;

      if (data.action === 'HIDE') {
        $(this.elementRef.nativeElement).hide();
        return;
      }

      this.elementRef.nativeElement.disabled = 'disabled';
    });
  }

  ngOnDestroy(): void {
    if (this.permissionsSub != null) {
      this.permissionsSub.unsubscribe();
    }
  }
}

export interface ActionAuthorization {
  allowAnonymous: boolean,
  allowAuthenticated: boolean,
  permissions: string[];
  action: 'HIDE' | 'DISABLE';
}
