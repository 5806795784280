import { Injectable } from '@angular/core';
import * as signalR from '@aspnet/signalr';
import { Subject } from 'rxjs';
import { ISignalRService } from 'src/app/@framework/signalr/signalr-service.interface';

@Injectable({
  providedIn: 'root'
})
export class SignalRService extends ISignalRService {

  protected connection: signalR.HubConnection;
  private readonly retryInterval = 1500;
  private nextRetryInterval = 0;
  private readonly maxRetries = 5;
  private reconectRetries = 0;
  private subjects: Subject<any>[] = [];

  constructor() {
    super();
  }

  public buildConnection(hubUrl) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(hubUrl)
      // .configureLogging(signalR.LogLevel.Information)
      .build();
  }

  public connect() {
    this.connection
      .start()
      .then(() => {
        this.connectionEstablished$.next(true);
        this.nextRetryInterval = this.retryInterval;
      })
      .catch(err => this.handleConnectionError(err));

    this.connection
      .onclose(err => this.handleConnectionError(err));
  }

  public disconnect() {
    this.connection.stop();
    this.connectionEstablished$.next(false);
  }

  public registerDefaultServerEvents () {
    this.connection.on('__connectedEvent', () => {

    });

    this.connection.on('forcePageReload', () => {
      window.onbeforeunload = null;
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  }

  private handleConnectionError(err: any): void {
    if (this.reconectRetries >= this.maxRetries) {
      return;
    }

    this.reconectRetries = this.reconectRetries + 1;

    this.nextRetryInterval = this.nextRetryInterval * 2;
    if (!isFinite(this.nextRetryInterval)) {
      return;
    }

    this.connectionEstablished$.next(false);
    setTimeout(() => this.connect(), this.nextRetryInterval);
  }
}
