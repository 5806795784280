<zapp-modal #FormModal [header]="formModalHeader" [footer]="formModalFooter" [style]="style">
  <ng-template #formModalHeader>
    <label zappLabel [size]="'lg'" [value]="''" [style]="'margin: 15px;'">
    </label>
    <zappIcon themeIcon="remove" style="float: right;" [size]="'lg'" (click)="closeModal()">
    </zappIcon>
  </ng-template>

  <router-outlet></router-outlet>

  <ng-template #formModalFooter>

  </ng-template>

</zapp-modal>