<p-button *ngIf="false && !hidden && isButton()" [styleClass]="class" (onClick)="onClick($event)" [disabled]="disabled"
  [icon]="icon">
  <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
</p-button>

<button *ngIf="!hidden && isButton()" pButton type="button" (click)="onClick($event)" [style]="_style"
  [disabled]="disabled" [icon]="icon">
  <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
</button>

<a *ngIf="!hidden && isLink()" href="javascript:void(0);" (click)="onClick($event)">
  <ng-container *ngTemplateOutlet="tempOutlet"></ng-container>
</a>

<div *ngIf="isImage()">
  <img [src]="_imageUrl" />
</div>

<ng-template #tempOutlet>
  <ng-content></ng-content>
</ng-template>