import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenubarModule } from 'primeng/menubar';
import { TranslateModule } from '@ngx-translate/core';
import { SecurityModule } from '../../security/security.module';
import { zAppMenuControlComponent } from './MenuControl.component';
import { MenuInternalService } from './MenuControl.service';
import {TieredMenuModule} from 'primeng/tieredmenu';
import { zAppDevIconismModule } from '@framework/components/Iconism/Iconism.module';

const MenuComponents = [ zAppMenuControlComponent ];
const MENU_PROVIDERS = [ MenuInternalService ];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SecurityModule,
    MenubarModule,
    TieredMenuModule,
    zAppDevIconismModule
  ],
  declarations: [...MenuComponents],
  exports: [...MenuComponents],
})
export class zAppDevMenuControlModule {
  static forRoot(): ModuleWithProviders<zAppDevMenuControlModule> {
    return {
      ngModule: zAppDevMenuControlModule,
      providers: [
        ...MENU_PROVIDERS
      ],
    };
  }
}
