import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Joove } from "@framework/core/Joove";
import { PermissionService } from '@framework/security/services/permission.service';
import { ThemeService } from "../../@theming/theme.service";

@Injectable({
  providedIn: 'root'
})
export class ContextService {

  constructor(private permissionService: PermissionService, private themeService: ThemeService) { }

  context$: Subject<Joove.IContext> = new Subject<Joove.IContext>();
  private context: Joove.IContext;
  private currentLanguage: string | null = null;

  update(context: Joove.IContext) {

    if (context.theme !== undefined) {
      this.themeService.setMainTheme(context.theme);
    }
    this.context = { ...this.context, ...context };
    window._context = this.context;
    this.context$.next(this.context);
    this.permissionService.updatePermissions(context.currentUserPermissions);

    // check if language has changed and reload page
    if (context.currentLanguage?.language == null || context.currentLanguage?.language.length <= 0) {
      return;
    }

    if (this.currentLanguage == null) {
      this.currentLanguage = context.currentLanguage.language;
      return;
    }

    if (this.currentLanguage === context.currentLanguage?.language) {
      return;
    }

    this.currentLanguage = context.currentLanguage.language;
    window.location.reload();

  }

}
